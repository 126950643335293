import React from "react";
import * as styles from "./subpage.module.scss";
import Layout from "../../../components/layout/layout";
import {OurServicesQuery} from "../../../../graphql-types";
import PageCover from "../../../components/common/page-cover/page-cover";
import LinksGroupSide from "../../../components/common/links-group-side/links-group-side";
import RichDatoCmsContent from "../../../components/rich-dato-cms-content/rich-dato-cms-content";
import useWindowWidth from "../../../hooks/use-window-width";

type ReactProps = {
    data: OurServicesQuery,
    selectors: any,
    richContent: any,
    seo: any,
    cover: any
}

const Subpage:React.FC<ReactProps> = ({data, selectors, richContent, seo, cover}) => {

    const isMobile = useWindowWidth() <= 1200;

    return (
        <Layout data={data.datoCmsFooter} stickyButtonsData={data.datoCmsWebStickyButton} chatbotData={data.datoCmsChatbot} seo={seo}         pageTemplate="ourServices sub page">
            <PageCover
                title={cover && cover.title}
                image={cover && cover.backgroundImage}
            />
            <section className={styles.pageContainer}>
                <div className={styles.pageContent}>
                    {!isMobile && <LinksGroupSide data={data.datoCmsProjet.linksGroup} />}
                    <div className={styles.content}>
                        <div className={styles.buttonsContainer}>
                            {selectors.map(selector => {
                                return (
                                    <a
                                        aria-label={selector.title}
                                        key={selector.id}
                                        className={selector.selected ? styles.buttonSelected : styles.button}
                                        href={selector.link}
                                    >
                                        {selector.title}
                                    </a>
                                )
                            })}
                        </div>
                        <RichDatoCmsContent data={richContent} />
                    </div>
                    {isMobile && <LinksGroupSide data={data.datoCmsProjet.linksGroup} />}
                </div>
            </section>
        </Layout>
    )

}

export default Subpage;