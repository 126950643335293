import React from "react";
import {graphql} from "gatsby";
import {OurServicesQuery} from "../../../../graphql-types";
import Subpage from "./subpage";

type ReactProps = {
    data: OurServicesQuery
}

const SchoolTrips:React.FC<ReactProps> = ({data}) => {

    const selectors = [
        {id: 1, title: data.datoCmsProjet.mainPageButtonTitle, content: data.datoCmsProjet.nosPrestationsContent, selected: false, link: "/vos-projets"},
        {id: 2, title: data.datoCmsProjet.contentYouthButtonTitle, content: data.datoCmsProjet.contentYouth, selected: true, link: "/vos-projets/sorties-scolaires"},
        {id: 3, title: data.datoCmsProjet.contentAdultsButtonTitle, content: data.datoCmsProjet.contentAdults, selected: false, link: "/vos-projets/colonies-de-vacances"},
        {id: 4, title: data.datoCmsProjet.contentSportsButtonTitle, content: data.datoCmsProjet.contentSports, selected: false, link: "/vos-projets/sorties-groupe-adultes"},
        {id: 5, title: data.datoCmsProjet.contentTourismAndEventsButtonTitle, content: data.datoCmsProjet.contentTourismAndEvents, selected: false, link: "/vos-projets/associations-clubs-sportifs"},
        {id: 6, title: data.datoCmsProjet.contentTourismProfessionalsButtonTitle, content: data.datoCmsProjet.contentTourismProfessionals, selected: false, link: "/vos-projets/professionnels-de-tourisme"}
    ];
    const sContent = selectors[1].content;

    return (
        <Subpage
            data={data}
            selectors={selectors}
            richContent={sContent}
            seo={data.datoCmsProjet.contentYouthSeo}
            cover={data.datoCmsProjet.contentYouthPageCover[0]}
        />
    )

}

export const pageQuery = graphql`
    query SchoolTrips($locale: String!) {  
        datoCmsFooter(locale: {  eq: $locale }) {
            ...FooterFields
        }
        datoCmsProjet(locale: { eq: $locale }) {
            ...ProjetFields
        }
        datoCmsWebStickyButton(locale: {  eq: $locale }) {
            ...StickyButtonsFields
        }
        datoCmsChatbot(locale: { eq: $locale }) {
            ...ChatbotFields
        }
    }
`

export default SchoolTrips;